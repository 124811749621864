@font-face {
  font-family: "arabic";
  src: url("../../../public/fonts/arabic/arabic.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "arabic";
  src: url("../../../public/fonts/arabic/arabic.ttf");
  font-weight: 500;
  font-display: swap;
}
h1,
h4 {
  color: #1e2432 !important;
  font-family: arabic !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 70px !important;
  margin: 0 !important;
}
h4 {
  font-size: 70px !important;
}
h1 {
  font-size: 80px !important;
  text-align: right;
}
.res-con-width {
  width: 392px !important;
}
html {
  min-width: 100%;

  /* overflow-y: hidden; */
}
.img1 {
  width: 138px;
  height: 60px;
}
.img2 {
  width: 247px;
  height: 58px;
}
.img3 {
  width: 223px;
  height: 78px;
}
.img4 {
  width: 195px;
  height: 66px;
}
.img5 {
  width: 157px;
  height: 66px;
}
.img6 {
  width: 160px;
  height: 80px;
}
.img7 {
  width: 125px;
  height: 86px;
}
.img8 {
  width: 183px;
  height: 74px;
}
.img9 {
  width: 144px;
  height: 72px;
}
.img10 {
  width: 197px;
  height: 86px;
}
.img11 {
  width: 235px;
  height: 66px;
}
.img12 {
  width: 211px;
  height: 86px;
}
.img13 {
  width: 202px;
  height: 44px;
}
.img14 {
  width: 152px;
  height: 60px;
}
.img15 {
  width: 223px;
  height: 54px;
}
.img16 {
  width: 271px;
  height: 72px;
}
.img17 {
  width: 105px;
  height: 72px;
}
.img18 {
  width: 103px;
  height: 62px;
}
.img19 {
  width: 192px;
  height: 48px;
}
.img20 {
  width: 193px;
  height: 62px;
}
.img21 {
  width: 197px;
  height: 54px;
}
.img22 {
  width: 171px;
  height: 54px;
}
.img23 {
  width: 195px;
  height: 76px;
}
.img24 {
  width: 171px;
  height: 54px;
}
body {
  min-width: 100vw;
  margin: 0;
  padding: 0;
}
.navbar {
  background: #ffffff;
  height: 72px !important;
  box-shadow: inset 0px -1px 0px #e8e9f5;
}
.footer-con {
  display: flex;
  flex-direction: row-reverse !important;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem !important;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: 0.75rem !important;
  padding-right: var(--bs-gutter-x, 0.75rem);
  width: 100%;
}
.banner-header {
  font-family: "arabic";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 71px;
  text-align: right;
  color: #000000;
  direction: rtl;
}
.banner-subtext {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: right;
  color: #333;
  direction: rtl;
}
.row-col {
  display: flex;
  flex-direction: row;
}
.row-col-rev {
  display: flex;
  flex-direction: row;
}
.abstract-section-con {
  position: relative;
  top: 100px;
}
.partner-slider-logo {
  width: 275px;
  height: 90px;
}
.abstract-section-text {
  z-index: 8;
  height: 100%;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.btn-primary.abstract-section-btn {
  padding: 12px 24px;
  background: #fff;
  border-radius: 1200px;
  width: 199px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  text-transform: uppercase;

  /* Neutral/White */

  color: #000;
}
.popup-btn-home {
  display: flex;
  justify-content: flex-end;
}
.btn-primary.abstract-section-btn:focus {
  background: #1e2432;
}
.abstract-section-text span {
  font-family: Cairo;
  font-style: normal;
  padding: 0 35px;
  font-weight: bold;
  width: 510px;
  position: absolute;
  font-size: 48px;
  color: #ffffff;
}
.slider-uni-logo {
  width: 100px;
  height: 53px;
  position: absolute;
  top: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.abstract-section-img {
  -webkit-transform: scaleX(-1);
  background-size: cover;
  width: 629px;
  height: 214px;
  transform: scaleX(1);
  background-image: url("https://cdn.ulearn.study/courses/abstract-shapes-1-2-min.png");
}
.abstract-section {
  height: 214px;
  background: #299cf7;
  border-radius: 15px;
  position: relative;
  margin-top: 134px;
  width: 1111px;
}
.contact-section-padding {
  padding-top: 140px;
  padding-bottom: 215px;
}
.abstract-btn-con {
  position: absolute;
  align-items: center !important;
  width: 25%;
}
.consult-section-img1 {
  background-size: cover !important;
  background-position: center center !important;
  width: 600px;
  height: 421px;
}
.consult-section-margin {
  margin-bottom: 64px;
}
.footer-section-end {
  display: flex;
  justify-content: flex-start;
}
.banner-btn {
  padding: 14px 32px !important;
  gap: 10px;
  height: 60px !important;
  border: none !important;
  background: #299cf7 !important;
  border-radius: 50px !important;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #299cf7 !important;
  border-color: #299cf7 !important;
}
.banner-btn:hover {
  text-decoration: none;
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #000 !important;
}
.footer-subtext {
  padding-top: 20px;
  direction: rtl;
  font-weight: normal !important;
  cursor: auto;
  text-align: right;
}
.numberText {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 71px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299cf7;
}
.banner-img {
  position: relative;
  top: 82px;
  width: 698px;
  height: 609px;
}
.whatsapp-fixed-icon-con {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 60px;
  z-index: 99;
}
.whatsapp-fixed-icon {
  height: 80px;
  width: 80px;
}
.navbar-light .navbar-toggler {
  border: none;
  outline: none;
}
.info-section-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
}
.info-section-subtext {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #676767;
  width: 267px;
}
.footer-popup:focus {
  border: none;
  outline: none;
}
.footer-popup {
  background-color: #fff;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 13.9806px;
  line-height: 28px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  color: #1e2432;
}
.calendly-btn {
  background-color: #299cf7 !important;
  border: none !important;
}
.career-progress {
  background: #e8e9f5 !important;
  border-radius: 8px !important;
  height: 6px !important;
  width: 586px !important;
}
.progress {
  width: 60% !important;
  background-color: rgba(205, 205, 205, 0.3) !important;
  border-radius: 8px !important;
  height: 16px !important;
}
.progress-bar {
  background-color: #299cf7 !important;
}
.btn-primary {
  padding: 7.98889px 23.9667px;
  gap: 9.99px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 44px;
  border: 2px solid #1e2432;
  border-radius: 121.831px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 13.9806px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #1e2432;
}
.nav-link {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #1e2432 !important;
}
.nav-link:hover {
  color: #299cf7 !important;
}
.nav-con {
  padding: 15px 0;
}
.partner-img {
  width: 100%;
  height: 287px;
  margin-bottom: 214px;
}
.pc-view {
  display: block;
}
h1 {
  font-family: arabic;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 70px;
  text-align: right;
  margin: 0;
  color: #1e2432;
}
h4 {
  font-family: arabic;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 70px;
  color: #1e2432;
  margin: 0;
}
p {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #191c1f;
  margin: 0;
}
.country-modal-header {
  padding: 116px 0 70px 0;
}
.partner-section-header {
  font-family: "arabic";
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 68px;
  text-align: center;
  color: #333333;
  padding-top: 90px;
}
.contact-section-header {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.banner-bottom-line {
  justify-content: end !important;
  padding: 31px 0;
}
.careerModal .modal-content {
  background: #fff !important;
  border-radius: 16px !important;
  min-height: 678px !important;
  width: 998px !important;
  box-shadow: none !important;
}

.careerModal .modal-content {
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: column !important;
  pointer-events: auto !important;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  outline: 0;
}
.contact-btn {
  background: #48c857;
  border: 1px solid #48c857;
  box-shadow: 0px 6px 16px rgba(15, 35, 41, 0.04);
  border-radius: 50px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  font-size: 21px;
  line-height: 40px;
  color: #ffffff;
  width: 247px !important;
  height: 56px;
}
.contact-btn:hover {
  text-decoration: none;
  color: #ffffff;
}
.contact-section-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 40px;
  /* identical to box height, or 190% */

  text-align: center;
  font-feature-settings: "ss04" on;

  /* Grey */

  color: #333333;
}
.partner-section-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  margin-bottom: 57px;
  text-align: center;
  padding-top: 20px;
  color: #676767;
}
.custom-dd {
  background: #ffffff !important;
  border: 1px solid #eae9f2 !important;
  border-radius: 0.3px !important;
  height: 56px !important;
}
.btn-success:hover {
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 16px;
  box-shadow: none;
}

.progress-bar {
  background: #299cf7;
}
.question-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: #333333;
}
.form-input {
  width: 392px !important;
  border: 1px solid #d7dfe9 !important;
  border-radius: 4px !important;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  height: 56px !important;
  font-size: 16px;
  color: #3f4247;
  padding: 0 15px;
}
.logo-slick-slider .slick-list {
  width: 80%;
}
.logo-slider-img-con {
  display: flex;
  justify-content: center;
}
.logo-slick-slider .slick-slider {
  display: flex !important;
  justify-content: center;
}
.accordion-item {
  border: none !important;
  border-bottom: 1px solid #a5a9ac !important;
}
.accordion-button:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.logo-slick-slider .slick-dots {
  bottom: -70px !important;
}
.accordion-btn-en .accordion-button::after {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/Group%2033863.png?alt=media&token=32dcdf9f-6aea-499e-ba8b-fe3202807ab3);
  font-size: 24px;
  margin-right: 0;
  margin-left: auto;
}

.accordion-btn-ar .accordion-button::after {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/Group%2033863.png?alt=media&token=32dcdf9f-6aea-499e-ba8b-fe3202807ab3);
  font-size: 24px;
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  content: "➖" !important;
  background-image: none;
  font-size: 18px;
  color: #fd3e60;
}

.accordion-btn-en .accordion-button {
  direction: ltr;
  font-family: Dm Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #000000;
}
.accordion-btn-ar .accordion-button {
  direction: rtl;
  font-family: Cairo;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}
.input-cross {
  position: absolute;
  top: 16px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  height: 56px !important;
  font-size: 16px;
  color: #3f4247;
  cursor: pointer;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.phoneInput-en .PhoneInputInput {
  border: 1px solid #d7dfe9;
  padding: 7px;
  border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  height: 56px;
  text-align: left;
  direction: ltr;
}
.phoneInput-ar .PhoneInputInput {
  border: 1px solid #d7dfe9;
  border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  padding: 7px;
  height: 56px;
  text-align: right;
  direction: ltr;
}

.PhoneInputCountry {
  border: 1px solid #d7dfe9;
  border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  padding: 7px;
  height: 56px;
  width: 88px;
  margin: 0 var(--PhoneInputCountrySelect-marginRight) 0 0;
}
.PhoneInputCountryIcon {
  width: 61px !important;
  height: 25px !important;
}

.PhoneInputCountry {
  border: 1px solid #d7dfe9;
  border-radius: 3px;
  padding: 7px;
  height: 56px;
  width: 88px;
  margin: 0 var(--PhoneInputCountrySelect-marginRight) 0 0;
}

.time-text {
  font-family: DM Sans;
  font-style: normal;

  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  text-align: right;

  color: #1e2432;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 64px;
  background: #ffffff;
  border: 1px solid #eae9f2 !important;
  border-radius: 16px !important;
  font-style: normal;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #333333 !important;
}
.react-tel-input:focus {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #299cf7 !important;
  box-sizing: border-box;
  outline: none;
}
.react-tel-input {
  font-family: "Cairo";
  font-size: 15px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}
.react-tel-input .flag-dropdown.open {
  background-color: #fff;
  border-right: none;
  border-color: #eae9f2 !important;
  border-radius: 16px 0 0 16px !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #fff;
  border-right: none;
  border-color: #eae9f2 !important;
  border-radius: 16px !important;
}
.react-tel-input .selected-flag {
  background-color: #fff;
  border-right: none;
  border-color: #eae9f2 !important;
  border-radius: 16px;
}
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  background-color: #fff;
  border-right: none;
  border-color: #eae9f2 !important;
  border-radius: 16px 0 0 16px !important;
}
.react-tel-input .flag-dropdown {
  background-color: #fff;
  border-right: none;
  border: 1px solid #eae9f2 !important;
  border-radius: 16px 0 0 16px !important;
}
.input-form {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border: 1px solid #eae9f2;
  border-radius: 0.3rem;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #333333;
}
input:focus,
input.form-control.phone-input:focus,
textarea.form-control.phone-input:focus {
  border-left: none !important;
}
.ans-btn:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  /* box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
  /* Lowering the shadow */
}
btn-primary:hover {
  background: #299cf7 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #1e2432;
}
input:focus,
input.form-control.phone-input:focus,
textarea.form-control.phone-input:focus .btn-outline-secondary:focus {
  border-right: 1px solid #e8e9f5 !important;
  border-left: none !important;
  border-top: 1px solid #e8e9f5 !important;
  border-bottom: 1px solid #e8e9f5 !important;
}
.custom-dob-dd .dropdown-toggle:hover {
  background-color: transparent !important;
}
.custom-dob-dd .dropdown-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 6px 12px;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border: 1px solid #a0abbb;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #191d23;
}
.custom-dob-dd .dropdown-menu {
  background: #ffffff;
  max-height: 400px;
  overflow: scroll;
  box-shadow: 0px 32px 48px -20px rgba(100, 112, 122, 0.15);
  border-radius: 4px;
  width: 100%;
}
.input-form::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  font-family: Cairo;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2 !important;
  color: #1e2432 !important;
}
.input-form:focus {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #299cf7 !important;
  box-sizing: border-box;
}
.complete-modal-body {
  overflow: hidden;
}
.complete-modal-header {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: right;
  padding-top: 24px;
  color: #1e2432;
}
.mobile-view {
  display: none !important;
}
.footer-container {
  background-color: #1b1b1b;
  padding: 80px 0;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.btn-outline-secondary:focus {
  background-color: #fff;
  box-shadow: none;
  outline: none;
  border-color: #eae9f2;
}
.btn-outline-secondary:active {
  background-color: #fff;
  box-shadow: none;
  outline: none;
  border-color: #eae9f2;
}
.btn-outline-secondary:hover {
  background-color: #fff;
  box-shadow: none;
  outline: none;
  border-color: #eae9f2;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #eae9f2;
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
  border-right: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 0;
}
.result-header {
  font-family: arabic;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 70px;
  text-align: center;
  color: #1e2432;
}
.result-card-header {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}
.input-group {
  border: 1px solid #eae9f2;
  border-radius: 16px;
}
.form-control-lg {
  height: 56px !important;
}
input:focus,
input.form-control.phone-input:focus,
textarea.form-control.phone-input:focus .btn-outline-secondary:focus {
  border: 1px solid #35a7ff !important;
}

#input-group-dropdown-1 {
  border-color: transparent;
}
.phone-input {
  border-color: transparent;
}
.result-card-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  padding-top: 22px;
  text-align: center;
  color: #333333;
}
.cal-add-btn {
  background: #1e2432;
  border-radius: 119.833px;
  color: #fff;
  position: absolute;
  bottom: 24px;
  width: 90% !important;
  font-weight: bold;
  padding: 7.98889px 23.9667px;
  cursor: pointer;
}
.cal-add-btn:hover {
  background: transparent !important;
  color: #1e2432;
  text-decoration: none;
  border: 2px solid #1e2432 !important;
}
.card-header {
  background-color: #fff !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #35a7ff !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: #35a7ff !important;
  color: #f0f8ff;
}
.DayPicker-Caption {
  /* display: table-caption; */
  margin-bottom: 0.5em;
  padding: 0.9em 0 0.8em 0 !important;
  text-align: left;
  border-bottom: 1px solid #e8e9f5;
}
.DayPicker-Day--today {
  color: #000 !important;
  font-weight: 500 !important;
  background-color: #fff;
}
.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 0.5em !important;
  left: auto;
  display: inline-block;
  margin-top: 0;
  width: 2em !important;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-Caption {
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center !important;
}
.DayPicker-wrapper {
  outline: none;
}
.DayPicker {
  width: 100%;
}
.DayPickerInput input {
  width: 100%;
  padding: 0 15px;
  border: 1px solid #eae9f2;
  border-radius: 16px;
  height: 64px;
  text-align: right;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #1e2432;
}
.DayPickerInput input::placeholder {
  opacity: 0.2;
}
.DayPickerInput {
  width: 100%;
}
.day-picker-custom-overlay {
  width: 100%;
  position: absolute;
  z-index: 2;
}
.custom-calender {
  text-indent: 40%;
  border: none;
  outline: none;
  appearance: none;
  background: url("../Images/ArrowDown.svg") no-repeat;
  background-position: left 35px top 50%;
}
/* Create the checkmark/indicator (hidden when not checked) */
.DayPicker {
  display: inline-block;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
}
.home-res-height {
  height: 65vh;
  overflow: hidden;
}
.DayPicker-Day {
  display: table-cell;
  padding: 0em !important;
  border-radius: 20px !important;
  vertical-align: middle;
  text-align: center;
  width: 40px !important;
  height: 40px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}
.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em 1em;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  color: #676767 !important;
  text-align: center;
}
.DayPicker-Caption > div {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
}
.partner-section {
  background: rgba(232, 233, 245, 0.25);
  margin-top: 154px;
  padding-bottom: 114px;
}
.logo-slick-slider .slick-dots {
  bottom: -70px;
}
.logo-slick-slider .slick-slide {
  height: 240px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

body.modal-open > .wrap {
  overflow: hidden;
  height: 100%;
}
.consaltation-header {
  font-family: "arabic";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 68px;
  text-align: center;
  color: #333333;
  padding: 103px 0 113px 0;
}
.consaltation-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 71px;
  padding-bottom: 30px;
  text-align: right;
  color: #000000;
}
.consaltation-subtext {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: right;
  color: #676767;
  direction: rtl;
  padding-bottom: 40px;
}
.offer-header {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 71px;
  text-align: right;
  color: #000000;
  padding-bottom: 16px;
}
.offer-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: right;
  color: #676767;
  padding-bottom: 52px;
}
.DayPicker-dashboard-wrap {
  font-size: 1.2rem !important;
  width: 88%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  min-height: 410px;
  margin-top: -410px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.qna-header {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 71px;
  text-align: right;
  color: #000000;
}
.qna-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: right;
  color: #676767;
  padding: 36px 0;
}
.DayPicker-dashboard {
  display: inline-block;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e8e9f5;
}
.result-card-btn {
  background: #299cf7 !important;
  border-radius: 8px !important;
  width: 100%;
  height: 61.75px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border: none !important;
}
.result-findout-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 70px;
  padding-bottom: 89px;
  text-align: center;
  color: #1e2432;
}
.result-card-listtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-right: 22px;
  line-height: 28px;
  text-align: right;
  color: #000000;
}
.search-input {
  width: 392px !important;
  border: 1px solid #d7dfe9 !important;
  border-radius: 4px !important;
  padding: 0 15px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  height: 56px !important;
  font-size: 16px;
  color: #3f4247;
}
.search-input::placeholder {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  direction: rtl;
  color: #99a5b4;
}
.result-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 41px;
  text-align: center;
  color: #1e2432;
  opacity: 0.6;
}
.contact-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}
.result-card-badge {
  background: #299cf7;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  top: 8px;
  width: 455px;
  z-index: 1;
  padding-bottom: 6px;
  color: #fff;
}
.result-card {
  background: #ffffff;
  border: 1px solid #299cf7;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 48px;
  width: 456px;
  z-index: 99;
}
.major-ans-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  color: #333333;
  direction: rtl;
}
.phone-input {
  border-left: none;
}
.complete-modal-subheader {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  color: #1e2432;
}
.remaining-time {
  font-family: Cairo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 28px;
  color: #1e2432;
}
.progress {
  width: 586px;
  height: 6px;
  background: #e8e9f5;
  border-radius: 8px;
}
.custom-dropdown-menu {
  width: 100%;
  text-align: right;
  height: 180px;
  overflow-y: scroll;
}
.custom-black-btn {
  background-color: #1e2432 !important;
  border-radius: 1200px !important;
  height: 56px !important;
  margin-top: 47px !important;
  font-family: Cairo !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13.9806px !important;
  line-height: 28px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  border: none !important;
  color: #ffffff !important;
}
.result-header-con {
  padding: 68px 0;
}
.custom-black-btn:active {
  background: #1e2432 !important;
}
.custom-black-btn:hover {
  background: #1e2432 !important;
}
.custom-black-btn:active {
  background: #1e2432 !important;
}
.banner-icon-con {
  width: 60vw;
  position: absolute;
  height: 54vw;
  bottom: 0;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.show > .btn-primary.dropdown-toggle {
  color: #191d23 !important;
  background: rgba(217, 217, 217, 0.3) !important;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #191d23 !important;
  background: rgba(217, 217, 217, 0.3) !important;
}
.phoneInput-ar .PhoneInputInput::placeholder {
  opacity: 0.5;
  font-family: Cairo;
}
.modal-content {
  width: 998px;
  min-height: 678px;
  background: #ffffff;
  border-radius: 16px;
}
.dropdown-toggle::after {
  content: none !important;
}
.banner-icon {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/major-bnr.png?alt=media&token=6190663b-a0ea-4b45-89d8-f71d882d90d7");
}
.custom-btn {
  border: 1px solid #1e2432;
  border-radius: 121.831px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 13.9806px;
  line-height: 28px;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  color: #1e2432;
}
.question-img {
  width: 370px;
  height: 134px;
}
@media (max-width: 767px) and (max-height: 767px) {
  .home-res-height {
    height: 120vh;
  }
}
/* @media (max-width: 767px) and (min-height: 992px) {
  .home-res-height {
    height: 50vh;
  }
} */
/* @media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
} */
@media (max-width: 1200px) {
  .nav-link {
    font-size: 13px !important;
    line-height: 24px;
  }
  .consult-section-img1 {
    width: 511px;
    height: 461px;
    margin-bottom: 64px;
  }
}
@media (max-width: 992px) {
  .result-header-con {
    padding: 30px 0;
  }
  .res-con-width {
    width: 100% !important;
  }
  .footer-subtext {
    padding-top: 10px;
    line-height: 18px !important;
    direction: rtl;
    font-weight: normal !important;
    cursor: auto;
    text-align: right;
  }
  .banner-icon {
    width: 100% !important;
    height: 100% !important;
  }
  .logo-slick-slider .slick-list {
    width: 100%;
  }
  .logo-slider-img-con {
    display: block;
    padding-left: 30px;
  }
  .custom-dob-dd {
    margin-bottom: 10px;
  }
  .lang-btn {
    width: max-content !important;
    border-radius: 16px !important;
  }
  .time-text {
    padding-top: 10px;
  }
  .career-progress {
    height: 6px !important;
    width: 100% !important;
  }
  h4 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .careerModal {
    display: block !important;
  }
  .careerModal .modal-content {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .dropdown {
    width: 100% !important;
  }
  body {
    background: #fff;
    height: auto;
    overflow: scroll !important;
  }
  body.modal-open {
    height: 100vh !important;
    overflow-y: hidden !important;
    position: fixed !important;
  }
  .search-input {
    width: 100% !important;
  }
  .contact-section-header {
    font-size: 24px;
    line-height: 44px;
  }
  .contact-section-text {
    font-size: 16px;
    line-height: 30px;
  }
  info-section-text {
    font-size: 21px;
    line-height: 40px;
  }
  .banner-btn {
    height: auto !important;
    width: 100%;
  }
  .banner-header {
    text-align: center;
    font-size: 32px;
    line-height: 58px;
  }
  .banner-subtext {
    text-align: center;
    font-size: 17px;
    line-height: 36px;
  }

  .consaltation-header {
    font-size: 50px;
    line-height: 70px;
    padding: 60px 0 23px 0;
    text-align: center;
  }
  .consaltation-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    padding-bottom: 6px;
  }
  .consaltation-subtext {
    font-size: 18px;
    line-height: 32px;
    padding-bottom: 30px;
  }
  .btn-primary {
    width: 100%;
    text-align: center;
  }
  .row-col {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .row-col-rev {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbar-collapse {
    position: absolute;
    background: rgb(255, 255, 255);
    width: 100%;
    top: 69px;
    padding-left: 39px;
    left: 0px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px;
    padding: 20px 0;
  }

  .result-header {
    font-size: 50px;
    line-height: 58px;
  }
  .result-findout-text {
    font-size: 26px;
    line-height: 40px;
  }
  .result-card-listtext {
    font-size: 20px;
  }
  .result-card {
    padding: 24px;
  }
  html {
    min-height: 100%;
    overflow-y: scroll;
  }
  .question-img {
    width: 299px;
    height: 110px;
  }
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    width: 100%;
    min-height: 100%;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal.show .modal-dialog {
    margin: 0;
    width: 100%;
  }
  .result-card-badge {
    width: 100%;
  }
  .result-card {
    width: 100%;
  }
  .inex-page-text-section {
    position: relative !important;
    align-items: center !important;
    justify-content: flex-start !important;
    right: 0 !important;
    width: 100%;
    padding: 20px;
  }
  .mobile-view {
    display: block !important;
  }
  .custom-black-btn {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .banner-text {
    padding-bottom: 30px !important;
    font-size: 70px !important;
  }
  h4 {
    font-size: 40px;
    line-height: 50px;
  }
  h1 {
    font-size: 58px;
    line-height: 70px;
    width: 100% !important;
  }
  .abstract-section-text span {
    width: 100%;
    z-index: 8;
    padding: 30px;
    font-size: 40px;
  }

  .abstract-section {
    height: 333px;
    margin-top: 60px;
  }
  .abstract-section-text {
    align-items: flex-start;
    width: 100%;
  }
  .abstract-btn-con {
    position: absolute;
    width: 100%;
  }
  .abstract-btn-con {
    align-items: flex-end !important;
    padding: 15px 24px;
  }
  .abstract-text-section {
    padding: 20px !important;
    padding-top: 20px !important;
    width: 100% !important;
    position: absolute;
    z-index: 99;
    font-size: 58px !important;
    line-height: 70px !important;
  }

  .abstract-section-img {
    background-size: cover;
    width: 260px;
    height: 100%;
    background-position: center center;
    background-image: url("https://cdn.ulearn.study/courses/abstract-responsive.png");
  }
  .banner-bottom-line {
    justify-content: center !important;
    padding: 18px 0 15px 0;
  }
  .bottom-line {
    width: 200px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    width: 100% !important;
    direction: rtl;
  }
  .pc-view {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .img1 {
    width: 69px;
    height: 30px;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .img2 {
    width: 124px;
    height: 29px;
  }
  .img3 {
    width: 112px;
    height: 39px;
  }
  .img4 {
    width: 98px;
    height: 33px;
  }
  .img5 {
    width: 79px;
    height: 33px;
  }
  .img6 {
    width: 80px;
    height: 41px;
  }
  .img7 {
    width: 63px;
    height: 43px;
  }
  .img8 {
    width: 114px;
    height: 55px;
  }
  .img9 {
    width: 72px;
    height: 36px;
  }
  .img10 {
    width: 99px;
    height: 43px;
  }
  .img11 {
    width: 118px;
    height: 33px;
  }
  .img12 {
    width: 111px;
    height: 43px;
  }
  .img13 {
    width: 101px;
    height: 22px;
  }
  .img14 {
    width: 76px;
    height: 30px;
  }
  .img15 {
    width: 116px;
    height: 28px;
  }
  .img16 {
    width: 120px;
    height: 34px;
  }
  .img17 {
    width: 53px;
    height: 36px;
  }
  .img18 {
    width: 52px;
    height: 31px;
  }
  .img19 {
    width: 96px;
    height: 24px;
  }
  .img20 {
    width: 97px;
    height: 31px;
  }
  .img21 {
    width: 99px;
    height: 27px;
  }
  .img22 {
    width: 86px;
    height: 27px;
  }
  .img23 {
    width: 98px;
    height: 38px;
  }
  .img24 {
    width: 86px;
    height: 27px;
  }
  .navbar-collapse {
    padding-left: 0;
  }
  .consaltation-header {
    text-align: right;
  }
  .res-w-100 {
    width: 100%;
  }
  .contact-section-padding {
    padding-top: 60px;
    padding-bottom: 65px;
  }
  .contact-btn {
    width: 100% !important;
  }
  .btn-primary.abstract-section-btn {
    width: 100%;
  }
  .partner-slider-logo {
    width: 92px;
    height: 30px;
  }
  .logo-slick-slider .slick-slide {
    height: 120px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .consult-section-margin {
    margin-bottom: 30px;
  }
  .res-con {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .whatsapp-fixed-icon-con {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }
  .popup-btn-home {
    display: flex;
    justify-content: center;
  }
  .footer-section-end {
    display: flex;
    justify-content: flex-end;
  }
  .partner-img {
    width: 100% !important;
    height: 118.88px !important;
    margin-bottom: 54px;
  }
  .partner-section-header {
    padding-top: 40px;
    font-size: 50px;
    line-height: 70px;
    text-align: right;
  }
  .partner-section {
    background: rgba(232, 233, 245, 0.25);
    margin-top: 20px;
  }
  .partner-section-text {
    text-align: right;
  }
  .banner-btn {
    font-size: 18px !important;
    padding: 14px 22px !important;
  }
  .banner-img {
    position: relative;
    height: 343px;
    width: 100%;
    top: 47px;
  }
  .footer-con {
    flex-direction: column !important;
  }
  .consult-section-img1 {
    width: 362px;
    height: 307px;
    margin-bottom: 0;
  }
  .info-section-subtext {
    padding-bottom: 30px;
  }
}

@media (max-width: 1300px) {
  .index-banner-img {
    width: 703px;
    height: 671px;
    display: block;
  }
}

@media (max-height: 850px) {
  .index-banner-img {
    width: 744px;
    height: 740px;
  }
  .banner-icon {
    width: 744px;
    height: 740px;
  }
}

@media (max-height: 850px) {
  .index-banner-img {
    width: 744px;
    height: 740px;
  }
  .banner-icon {
    width: 744px !important;
    height: 740px !important;
    position: absolute;
    bottom: 0;
  }
}
@media (max-height: 650px) {
  .index-banner-img {
    width: 744px;
    height: 740px;
  }
  .banner-icon {
    width: 644px !important;
    height: 640px !important;
    position: absolute;
    bottom: 0;
  }
}

@media (max-height: 850px) and (max-width: 992px) {
  .banner-icon {
    width: 100% !important;
    height: 100% !important;
  }
  body {
    height: 100%;
  }
  .banner-icon-con {
    width: 100%;
    position: fixed;
    height: 400px !important;
    bottom: 0;
  }
}
