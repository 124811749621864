.form-input-custom {
  background: rgba(217, 217, 217, 0.3);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  padding: 9px 13px;
  margin-bottom: 24px;
  height: 56px;
  // border: 1px solid #d7dfe9 !important;
  border-radius: 200px !important;
  width: 100%;
  &:hover {
    border: 1px solid #299cf7;
    outline: none;
  }
  &:focus {
    border: 1px solid #299cf7;
    outline: none;
  }
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    direction: rtl;
  }
}
