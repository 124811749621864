// .PhoneInput .PhoneInputInput:focus {
//   border: none !important;
// }
h3 {
  margin: 0;
}
.res-con-width {
  width: 392px !important;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: Cairo;
  font-display: swap;
  scroll-behavior: smooth;
}
.teachers-text-con {
  padding: 24px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin: 0 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;

    border-radius: 4px;
    border: 1.5px solid #b8c0cc;
  }

  // Box hover
  &:hover + label:before {
    background: #299cf7;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: #299cf7;
    border-radius: 4px;
    border: 1.5px solid #299cf7;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    right: 13px;
    top: 11px;
    background: white;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
li {
  margin: 0 0 20px 0 !important;
}
label {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  font-feature-settings: "ss04" on;
  color: #000000;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 20px 0;
}
ul {
  direction: rtl;
}
.centered {
  width: 300px;
  margin: auto;
}

.title {
  text-align: center;
  color: rgb(69, 113, 236);
}
.form-input-custom {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  padding: 9px 13px;
  margin-bottom: 24px;
  height: 56px;
  border: 1px solid #d7dfe9 !important;
  width: 100%;
  border-radius: 200px !important;
  background: rgba(217, 217, 217, 0.3);
  &:hover {
    border: 1px solid #fd3e60;
    outline: none;
  }
  &:focus {
    border: 1px solid #fd3e60;
    outline: none;
  }
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    direction: rtl;
  }
}

.course-card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  color: #333333;
}
.level-text {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: right;
  color: #299cf7;
}
.course-step-row {
  display: flex;
  flex-direction: row;
}
.teachers-title {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  color: #333333;
  font-size: 30px;
  text-align: center;
  line-height: 48px;
}
.teachers-subtitle {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #676767;
}
.teacher-section-margin {
  margin-bottom: -90px;
}
.dedicatedpage-blue-section {
  height: 216px;
}

.level-subtext {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  line-height: 28px;
  padding-bottom: 24px;
  color: #333333;
}
.course-card-subtitle {
  font-style: normal;
  font-weight: 600;
  padding: 8px;
  margin-top: 12px;
  font-size: 21px;
  line-height: 30px;
  text-align: center;
  color: #676767;
}
.course-scrollable-img-section {
  height: 622px;
  display: flex;
  justify-content: center;
  background: #299cf7;
}
.react-multi-carousel-track {
  height: 463px;
}
.carousel-button-group {
  position: absolute;
  padding: 0 20px;
  bottom: 0;
  right: 32%;
}
.course-blue-card-section {
  padding: 80px 0;
}
.course-scrollable-text {
  margin-right: 24px;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: #fff;
}
.certificate-img {
  width: 100%;
  height: 540px;
}
.course-whiteBtn {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(15, 35, 41, 0.04);
  border-radius: 6px;
  color: #299cf7;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  height: 52px;
  // margin-top: 24px;
  padding: 14px;
}
.placement-header {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 68px;
  text-align: center;
  color: #191c1f;
  margin-bottom: 48px;
}
.course-whiteBtn:hover {
  background: #ffffff;
  color: #299cf7;
}
.course-blue-section-text {
  font-style: normal;
  font-weight: bold;
  margin-bottom: 30px;

  font-size: 30px;
  /* identical to box height, or 187% */

  text-align: center;

  color: #ffffff;
}
.course-blue-section {
  height: 216px;
  border-radius: 8px;
}
.course-step-text {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 39px;
  color: #333333;
  text-align: center;
}
.course-step-subtext {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #676767;
}
.course-card-title-section {
  padding: 116px 0 80px 0;
}
.course-card-section {
  background: #ffffff;
  box-shadow: 0px 12px 36px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  padding: 14px 24px;
  width: max-content;
  margin-bottom: 16px;
}
.course-page-section {
  padding-top: 116px;
}
.course-subscription-section {
  width: 55%;
}
.course-card-section-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  margin-right: 24px;
}
.res-banner-reverse {
  -webkit-overflow-scrolling: auto;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.course-page-partner-icon {
  width: 252px;
  height: 57.71px;
}
.DayPickerInput input {
  width: 100%;
  border: 1px solid #e8e9f5;
  padding: 0 15px;
  border-radius: 4px;
  height: 52px;
}
.DayPickerInput {
  width: 100%;
}
.day-picker-custom-overlay {
  width: 100%;
  position: absolute;
  z-index: 2;
}
p {
  margin: 0;
}
.custom-modal .modal-dialog {
  min-width: 588px;
}
.placement-custom-modal .modal-dialog {
  max-width: 400px;
  min-width: 350px;
  min-height: 800px;
  margin-top: 200px;
}
.placement-index-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #191c1f;
}
.placement-button-container {
  width: 58% !important;
  margin-top: 35px;
  .placement-button {
    margin-bottom: 15px;
  }
}

.placement-card-container {
  margin-bottom: 5rem;
  margin-left: 1vw;
  margin-right: 1vw;
  .placement-card {
    width: 49%;

    background: #ffffff;
    border: 1px solid #e8e9f5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px !important;

    &:hover {
      box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
    }

    .placement-button {
    }
  }
}
.video-text-section {
  padding: 40px 48px 40px 48px;
}

.child-card-btn {
  width: 119px;
  height: 52px;
  background: #299cf7;
  border: 1px solid #299cf7;
  box-sizing: border-box;
  border-radius: 8px;
}
.course-subscription-card-discount {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}
.video-card {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 8px;
}
.result-con-card {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px;
}
.result-con-card-p {
  font-weight: bold;
  font-size: 17px;
  margin-right: 21px;
  text-align: right;
  margin-bottom: 8px;
}
.course-banner-border {
  position: absolute;
  left: 77.78%;
  right: 8.33%;
  width: 200px;
  height: 5.14px;
  top: 4.34%;
  bottom: 95.6%;
  background: #299cf7;
}
.course-banner-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  text-align: right;
  color: #333333;
}
.course-banner-con {
  position: absolute;
  top: 110px;
}
.course-banner-con-hide-laptop {
  display: none;
}
.course-border-con {
  display: flex;
  justify-content: flex-end;
}
.course-banner-blue-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  text-align: right;
  color: #299cf7;
}
.course-banner-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-align: right;
  color: #676767;
}
.course-banner-subtext-bold {
  font-family: Cairo;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 40px;
  text-align: right;
  color: #333333;
}
.CircularProgressbar .CircularProgressbar-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  fill: #299cf7 !important;
}
.child-card-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  color: #676767;
}
.child-card-time {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #191c1f;
}
.kid-test-header {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  height: 88px;
  margin-bottom: 40px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.placement-con {
  height: 100vh;
}
.circle-box-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.progress-bar-section {
  width: 282px;
  padding-bottom: 8px;
}
.complete-custom-card {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  padding: 24px !important;
}
.custom-box span {
  font-family: Cairo;
  font-style: normal;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
  color: #191c1f;
}
.custom-box:hover span {
  color: #299cf7;
}
.custom-box img {
  margin-left: 20px;
}
.CircularProgressbar-path {
  stroke: #299cf7 !important;
}
.CircularProgressbar-trail {
  stroke: #e8e9f5 !important;
}
.complete-card-title {
  font-family: Cairo;
  font-style: normal;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.complete-subs-title {
  font-family: Cairo;
  font-style: normal;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.complete-card-subtitle {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #676767;
}
.complete-subs-subtitle {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #676767;
}
.custom-box {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 50%;
  padding: 15px;
  margin-bottom: 12px;
  cursor: pointer;
}
.complete-text-card {
  background: rgba(232, 233, 245, 0.25);
  padding: 56px 145px;
}
.custom-box:hover {
  border: 1px solid #299cf7;
}
.fade-in-left {
  animation: fadeInLeft 2s;
}
.scroll-section-title-margin {
  margin-bottom: -140px;
}
.subscription-card .first .fade-in-element {
  animation: fade-in 1.4s;
  animation-delay: 0s;
}
.subscription-card .sceond .fade-in-element {
  animation: fade-in 1.4s;
  animation-delay: 1s;
}
.subscription-card.third .fade-in-element {
  animation: fade-in 1.4s;
  animation-delay: 2s;
}
.fade-in-element {
  animation: fade-in 1.4s;
}
.fade-in-element2 {
  animation: fade-in2 1s;
}
.hidden {
  opacity: 0;
}
.hidden-review {
  opacity: 0;
}
.svg-box-content {
  width: 542px;
  height: 423px;
}
.svg-box-content-faq {
  width: 542px;
  height: 423px;
  position: absolute;
}
//?Grid Column
.html5-video-player div {
  display: none !important;
}
.section {
  clear: both;
  height: 200px;
  direction: rtl;
  padding: 0px;
  margin: 0px;
  position: relative;
  top: 69px;
}
.error {
  float: right;
  margin-bottom: 12px;
  color: #299cf7;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
/*  COLUMN SETUP  */
.col {
  display: block;
  padding-left: 9px;
  float: left;
  // margin: 1% 0 1% 2%;
}
.col:first-child {
  margin-left: 0;
}
.booking-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #333333;
}
.youtube-video {
  width: 474px !important;
  height: 355px;
  border-radius: 6px !important;
}
.iframe-video-faq {
  position: absolute;
  width: 100%;
  height: 100%;
  top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placement-timer-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #676767;
}
.video-width-faq {
  width: 571px;
  height: 498px;
  position: relative;
  top: 55px;
  left: 10px;
}
.iframe-video {
  overflow: hidden;
  -webkit-overflow-scrolling: touch !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.course-page-banner-icon {
  padding: 60px 0 0 0;
}
.laptop-view {
  display: block;
}
.mobile-view {
  display: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.child-card-btn-mobile {
  display: none;
}
.booking-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #676767;
  /*  GROUPING  */
}
.group:before,
.group:after {
  content: "";
  display: table;
}
.group:after {
  clear: both;
}
.placement-note-text {
  width: 352px;
}
.group {
  zoom: 1; /* For IE 6/7 */
}
.about-banner-container {
  height: 110vh;
}
.about-banner {
  height: 110vh;
}
.span_1_of_5 {
  width: 20%;
  height: 200px !important;
}
.booking-success-card {
  background: rgba(232, 233, 245, 0.25);
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 24px;
  width: 588px;
}
.placement-hero-container {
  padding-top: 145px;
  padding-bottom: 150px;
}
/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
  .col {
    margin: 1% 0 1% 0%;
  }
  .span_1_of_5,
  .span_2_of_5,
  .span_3_of_5,
  .span_4_of_5,
  .span_5_of_5 {
    width: 100%;
  }
}
.banner-vedio-holder {
  width: 499px;
}
.res-banner-reverse {
  height: 100vh;
}
.scroll-section {
  width: 1070px !important;
}
.scroll-section-kids {
  width: 370px !important;
}
.panel-group {
  margin-top: 30px;
}
.res-faq-border {
  border-bottom: 1px solid #e3e9ef;
}
.banner-cente {
  justify-content: flex-start !important;
}
.card {
  border: none !important;
  background-color: rgb(249, 249, 252);
}

button:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.card-body {
  flex: 1 1 auto;
  padding: 0 0 0 1.25rem;
}
.faq-selection:focus {
  color: red !important;
}
.card:focus {
  border: none !important;
}
.custom-calender {
  text-indent: 40%;
  border: none;
  outline: none;
  appearance: none;
  // background: url("../Icons/BlackArrowDown.svg") no-repeat;
  background-position: left 35px top 50%;
}
.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
.btn-primary:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.panel-close {
  max-height: 0;
}

.card-header {
  padding: 0 !important;
  margin-bottom: 0;
  background-color: rgba(232, 233, 245, 0.25) !important;
  border-bottom: none !important;
}
.desktop-carousel-container {
  max-width: 1070px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.desktop-container {
  max-width: 1200px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.phone-dd .dropdown-menu {
  min-width: 358px;
  width: 100%;
}
.test-phone-input .dropdown-menu {
  min-width: 334px !important;
  width: 100%;
}
// .dropdown, .dropleft, .dropright, .dropup{
//   position: unset !important;
// }
.form-control.error {
  border: 1px solid #f00;
}
input:focus,
input.form-control.phone-input:focus,
textarea.form-control.phone-input:focus {
  border-left: none !important;
}
input:focus,
input.form-control.phone-input:focus,
textarea.form-control.phone-input:focus .btn-outline-secondary:focus {
  border-right: 1px solid #e8e9f5 !important;
  border-left: none !important;
  border-top: 1px solid #e8e9f5 !important;
  border-bottom: 1px solid #e8e9f5 !important;
}
.btn-outline-secondary {
  background-color: #fff !important;
  border-left: 1px solid #e8e9f5 !important;
  border-right: none !important;
  border-top: 1px solid #e8e9f5 !important;
  border-bottom: 1px solid #e8e9f5 !important;
}
.btn-outline-secondary:focus {
  border-left: 1px solid #e8e9f5 !important;
  border-right: none !important;
  border-top: 1px solid #e8e9f5 !important;
  border-bottom: 1px solid #e8e9f5 !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #fff !important;
  border-left: 1px solid #e8e9f5 !important;
  border-right: none !important;
  border-top: 1px solid #e8e9f5 !important;
  border-bottom: 1px solid #e8e9f5 !important;
}
.hide-for-laptop-complete {
  display: none !important;
}
.react-multi-carousel-item {
  width: 287px !important;
}
.containerCircle {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.containerCircle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmarkCircle {
  position: absolute;
  top: 14px;
  right: 0;
  border: 1px solid #299cf7;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
}
.zlive-con {
  margin-left: -55px;
}
/* On mouse-over, add a grey background color */
.containerCircle:hover input ~ .checkmarkCircle {
  background-color: #fff;
}
.svg-safari {
  -webkit-filter: url("#prefix__filter0_b") !important;
  filter: url("#prefix__filter0_b");
}
/* When the radio button is checked, add a blue background */
.containerCircle input:checked ~ .checkmarkCircle {
  background-color: #299cf7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkCircle:after {
  content: "";
  position: absolute;
  display: none;
}
.complete-icon-container {
  justify-content: center;
  display: flex;
  position: relative;
  padding: 60px;
  border-radius: 4px;
  border: 1px solid #e8e9f5;
}
.complete-icon-container p {
  width: 100%;
  text-align: center;
  bottom: 0 !important;
  background-color: rgba(232, 233, 245, 0.25);
  padding: 5px;
}

.complete-icon-container img {
  width: 340px;
}
.complete-page-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  text-align: right;
  color: #191c1f;
  padding-top: 40px;
}
.complete-page-bluetext {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: right;
  direction: rtl;
  color: #35a7ff;
  padding-top: 24px;
}
.complete-icon-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}
.complete-icon-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  margin-top: 4px;
  text-align: center;
  color: #ffffff;
}
.complete-icon-btn {
  width: 228px;
  height: 52px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 40px;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #299cf7;
  border: none;
  cursor: pointer;
}
.complete-icon-btn:hover {
  background: #ffffff;
  color: #299cf7;
  border: none;
}
.complete-page-subtext {
  font-family: Cairo;
  padding-top: 24px;
  padding-bottom: 116px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 2;
  text-align: right;
  color: #191c1f;
}
.complete-icon-inner-container {
  width: 588px !important;
}
/* Show the indicator (dot/circle) when checked */
.containerCircle input:checked ~ .checkmarkCircle:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerCircle .checkmarkCircle:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.slick-slide {
  padding: 0px 0px 0px 15px;
}
.accordion-button:not(.collapsed) {
  color: #299cf7;

  background-color: #fff;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.navbar-brand {
  transition: 0.4s !important;
}
.test-header {
  -webkit-animation: FADE 0.4s;
  -moz-animation: FADE 0.4s;
  -o-animation: FADE 0.4s;
  animation: FADE 0.4s;
  animation-fill-mode: forwards;
}
.hide-for-laptop {
  display: none !important;
  left: 0;
}
.navbar {
  transition: 0.4s;
}
.navbar-light .navbar-nav .nav-link {
  font-style: normal;
  font-weight: 600;
  margin-left: 40px;
  transition: 0.4s;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #333333;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.failed-booking-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 68px;
  color: #333333;
}
.failed-booking-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #676767;
}
.checkout-form-card {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  padding: 24px;
}
.form-control {
  border: 1px solid #e8e9f5;
  border-radius: 4px;
  color: #333 !important;
}
input,
textarea.form-control {
  direction: rtl;
}
.hide-for-laptop-com {
  display: none;
}
input[type="text"]::placeholder {
  font-style: normal;
  font-family: Cairo;
  font-weight: normal;
  font-size: 14px !important;
  vertical-align: middle;
  color: #676767;
  direction: rtl;
  // display: flex;
  // align-items: center;
}
.complete-con {
  padding: 40px 24px 0 24px;
}
input:focus,
input.form-control:focus,
textarea.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  padding-left: 16px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
input:focus,
input.form-control:focus,
textarea.form-control:focus {
  border: 1px solid #35a7ff !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: #000;
}
.faq-selection::selection {
  background: transparent;
}
.full-width-card {
  position: absolute;
  left: 0;
  width: 100%;
}
.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.close {
  float: right;
  font-size: 2rem;
  font-weight: 100;
  line-height: 1;
  color: #333333;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.modal-footer > :not(:first-child) {
  margin-left: 0em !important;
}
.modal-footer > :not(:last-child) {
  margin-right: 24px !important;
}
.complete-modal .modal-dialog .modal-content {
  width: 384px !important;
}
.modal-backdrop {
  background: rgba(255, 255, 255, 0.85);
}
.modal-backdrop.show {
  opacity: 1;
}
.modal-content {
  width: 100%;
  border: 1px solid #f4f4f4 !important;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 4px !important;
}
.placement-index-svg {
  width: 432.88px;
  height: 380px;
}
.modal-header {
  flex-direction: row-reverse;
  border: none !important;
}
.modal-footer {
  border: none !important;
}
.modal-header .close {
  padding: 1rem 0rem;
  margin: -1rem 0;
}
.phone-no {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;

  color: #299cf7;
}
.nav-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  padding: 8px 24px;
  color: #333333;
  background-color: #fff;
  border-color: #333333;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: center;
}
.nav-btn:hover {
  background-color: #333333 !important;
  border-color: #333333;
}
.footer-container {
  background-color: #1b1b1b;
  // min-height: 224px;
  padding: 80px 0;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.laptop-footer {
  display: flex;
}
.mobile-footer {
  display: none;
}
.scroll-header {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  color: #333333;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  border: 1px solid #35a7ff !important;
}
.image-holder-container {
  direction: rtl;
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  // background-color: DodgerBlue;
  overflow-x: hidden;
}
.input-group-text {
  background-color: transparent !important;
}
.video-width {
  width: 567px;
}
.banner-center {
  height: 497px;
}
.image-holder-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: none;
}

.image-holder-container::-webkit-scrollbar-track {
  box-shadow: none;
}
.image-holder-container::-webkit-scrollbar {
  width: 0;
}
.faq-card-res-whatspptext::selection {
  background: transparent;
}
.faq-card-res-text::selection {
  background: transparent;
}
.faq-card-res-subtext::selection {
  background: transparent;
}
.image-holder {
  min-width: 220px;
  height: 220px;
  margin-right: 15px;
  background: linear-gradient(
    180deg,
    rgba(183, 212, 255, 0.15) 0%,
    rgba(0, 0, 0, 0.1425) 80.7%,
    rgba(0, 0, 0, 0.15) 100%
  );
  box-shadow: 0px 4px 12px rgba(15, 35, 41, 0.2) !important;
  border-radius: 8px;
}
.subcription-section-laptop {
  display: block;
}
.subcription-section-mobile {
  display: none !important;
}

.subscription-card-tab {
  height: 52px;
  background: rgba(232, 233, 245, 0.25);
  border-radius: 4px;
}
.subscription-card-tab-active {
  width: 106.33px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 4px;
  color: #299cf7;
}
.hide-for-laptop-relative {
  position: relative;
  display: none;
}
.res-phone-icon {
  display: none;
}
.coupon-button {
  font-style: normal;
  font-weight: 600;
  padding: 14px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  border: 1px dashed #299cf7;
  border-radius: 8px;
  color: #299cf7;
}
.subscription-card {
  background: #ffffff;
  width: 100%;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-top: 7px solid #e8e9f5;
  border-radius: 8px;
  margin: 0px;
}
// .subscription-card:hover .subscription-card-desc {
//   font-style: normal;
//   font-weight: normal;
//   font-size: 17px;
//   color: #35a7ff;
// }
.coming-soon-mobile {
  display: none;
}
.coming-soon-laptop {
  display: block;
}
.subscription-card-desc {
  font-weight: 600;
  font-size: 17px;
  direction: rtl;
  color: #676767;
}
.plan-section {
  margin-top: 658px;
}
.subscription-card-header {
  margin: 0 40px;
  border-bottom: 1px solid #e8e9f5;
}
// .subscription-card:hover .subscription-card-header {
//   border-bottom: 1px solid #35a7ff;
// }
// .subscription-card:hover {
//   border: 1px solid #35a7ff;
//   margin: -1px;
//   box-sizing: border-box;
//   box-shadow: 0px 4px 12px rgba(15, 35, 41, 0.08);
//   border-radius: 8px;
// }
.checkout-price {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  display: flex;
  align-items: center;
  color: #333333;
}
.res-steps-width {
  width: 490px;
}
.tag-pricing {
  left: -4px !important;
}
.checkout-price::after {
  content: "دك";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  top: 10px;
  left: 4px;
  position: relative;
  color: #333333;
}
.checkout-price::before {
  content: "دك";
  font-style: normal;
  top: 10px;
  right: 4px;
  position: relative;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #333333;
}
.discounted-price {
  position: relative;
  top: 34px;
  left: -10px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333;
}
.discounted-price-line {
  position: relative;
  right: -19px;
  top: 47px;
  width: 13px;
  height: 1.52px;
  background: #299cf7;
  transform: matrix(-3.71, 0.7, 0.71, 0.71, 0, 0);
}
.count-down-text {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299cf7;
}
.test-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  color: #6b6c7f;
}
.test-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: right;
  color: #191c1f;
}
.count-down-subtext {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 0 20px;
  text-align: center;
  color: #676767;
}
.scrollable-card-icon-effect {
  position: relative;
  bottom: 50px;
  transition: all 0.2s ease 0s;
  box-shadow: 0px 16px 24px rgba(15, 35, 41, 0.1);
  width: 108px;
  border-radius: 54px;
  height: 108px;
}
.background-hover:hover .scrollable-card .scrollable-card-icon-effect {
  position: relative;
  top: 20px !important;
  transition: all 0.2s ease 0s;
  box-shadow: 0px 16px 24px rgba(15, 35, 41, 0.1);
  width: 68px;
  border-radius: 54px;
  height: 68px;
  bottom: 0;
}
// .background-hover:hover .scrollable-card .scrollable-card-icon-effect {
//   width: 68px;
//   height: 68px;
//   bottom: 0;
//   // top: 30px;
//   opacity: 1;
// }
.svg-box-video {
  width: 280px;
}
_::-webkit-full-page-media,
_:future,
:root .svg-box-video {
  width: 280px !important;
}
// .scrollable-card-hover{
//   display: none;
// }
// .scrollable-card:hover{
//   display: none;
// }
// .scrollable-card-wrapper:hover .scrollable-card {
//   display: none;
// }
// .scrollable-card-wrapper:hover .scrollable-card-hover{
//   opacity: 1;
//   position: relative;
//   bottom: 0;
//   left: 16px;
// }
.mb-3,
.my-3 {
  margin-bottom: 12px !important;
}
.about-text-container {
  max-width: 781px;
}
.form-control-lg {
  height: 52px !important;
  padding: 0 1rem !important;
  line-height: 52px;
  font-size: 16px;
}
.contact-container {
  width: 894px;
  padding: 0 24px;
}
.custom-container {
  width: 590px;
}
.res-width {
  width: 70%;
}
.success-purchase-card {
  min-width: 334px !important;
}
.about-card {
  background: rgba(232, 233, 245, 0.25);
  border-radius: 8px;
  // min-height: 338px;
  width: 100%;
  padding: 80px 0;
  margin-top: 116px;
}
.res-flex-end {
  display: flex;
  justify-content: flex-end;
}
.res-section-center {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
.res-flex-start {
  display: flex;
  justify-content: flex-start;
}
.banner-padding-top {
  text-align: right;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
}
.comming-soon-icon {
  position: absolute !important;
  bottom: 150px;
  width: 100%;
  left: 0;
}
/* Checkbox Category */

.container12 {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.container12 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.about-baner-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 68px;
  color: #ffffff;
  text-align: center;
}
.failed-booking-img {
  width: 240px;
  height: 167.51px;
}
.about-baner-subtext {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  text-align: center;

  color: #ffffff;
}
.checkmark {
  position: absolute;
  top: 3px;
  right: -2px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #35a7ff;
  box-sizing: border-box;
  border-radius: 4px;
}

.dropdown-toggle::after {
  margin-left: 10px;
}
.dropdown-item.drop-down-date {
  padding: 0 !important;
  border: none !important;
}
.container12 .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container12:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */

.container12 input:checked ~ .checkmark {
  background-color: #35a7ff;
}
.DayPicker-wrapper {
  outline: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.DayPicker {
  display: inline-block;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  // border: 1px solid #e8e9f5;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
}
.DayPicker-Day {
  display: table-cell;
  padding: 0em !important;
  border-radius: 20px !important;
  vertical-align: middle;
  text-align: center;
  width: 40px !important;
  height: 40px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}
.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  color: #676767 !important;
  text-align: center;
}
.DayPicker-Caption > div {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
}
.DayPicker-dashboard-wrap {
  font-size: 1.2rem !important;
  width: 88%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  min-height: 410px;
  margin-top: -410px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.DayPicker-dashboard {
  display: inline-block;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 1;
  position: relative;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e8e9f5;
}
.slick-list {
  width: 80%;
}
.slick-slider {
  display: flex !important;
  justify-content: center;
}
.accordion-item {
  border: none !important;
  border-bottom: 1px solid #a5a9ac !important;
}
.accordion-button:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.slick-dots {
  bottom: -40px !important;
}

.accordion-btn-en .accordion-button::after {
  background-image: url("https://storage.googleapis.com/uapply/PlusIcon.png");
  font-size: 24px;
  margin-right: 0;
  margin-left: auto;
}

.accordion-btn-ar .accordion-button::after {
  background-image: url("https://storage.googleapis.com/uapply/PlusIcon.png");
  font-size: 24px;
  margin-right: auto;
  margin-left: 0;
}
.accordion-button:not(.collapsed)::after {
  content: "➖" !important;
  background-image: none;
  background-image: none !important;
  font-size: 18px;
  color: #299cf7;
}
.succcess-animation {
  width: 60%;
  height: 90vh;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.fade-in-bottom {
  animation: fadeInDown 1s;

  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translatey(-50px);
      -moz-transform: translatey(-50px);
      -o-transform: translatey(-50px);
      transform: translatey(-50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translatey(0);
      -moz-transform: translatey(0);
      -o-transform: translatey(0);
      transform: translatey(0);
    }
  }
  @-moz-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translatey(-50px);
      -moz-transform: translatey(-50px);
      -o-transform: translatey(-50px);
      transform: translatey(-50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translatey(0);
      -moz-transform: translatey(0);
      -o-transform: translatey(0);
      transform: translatey(0);
    }
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translatey(-50px);
      -moz-transform: translatey(-50px);
      -o-transform: translatey(-50px);
      transform: translatey(-50px);
    }
    to {
      opacity: 1;
      -webkit-transform: translatey(0);
      -moz-transform: translatey(0);
      -o-transform: translatey(0);
      transform: translatey(0);
    }
  }
}

.accordion-btn-en .accordion-button {
  direction: ltr;
  font-family: Dm Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #000000;
}
.accordion-btn-ar .accordion-button {
  direction: rtl;
  font-family: Cairo;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}
// .PhoneInputCountryIcon--border {
//   box-shadow: none !important;
// }
.calender-input-container:active {
  border: 1px solid #299cf7 !important;
}
.calender-input-container {
  padding: 14px 16px;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 12px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #35a7ff !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ) {
  position: relative;
  background-color: #35a7ff !important;
  color: #f0f8ff;
}
.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  margin-left: -27px;
  margin-right: -27px;
  padding: 0 0 0.8em 0 !important;
  text-align: left;
  border-bottom: 1px solid #e8e9f5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 24px 24px 24px;
}
.modal-header {
  padding: 24px;
}
.res-social-con {
  -webkit-animation: FADE 5s;
  -moz-animation: FADE 5s;
  -o-animation: FADE 5s;
  animation: FADE 5s;
  animation-fill-mode: forwards;
}
@-webkit-keyframes FADE {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes FADE {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes FADE {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes FADE {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.DayPicker-Day--today {
  color: #000 !important;
  font-weight: 500 !important;
  background-color: #fff;
}
.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 0.5em !important;
  left: auto;
  display: inline-block;
  margin-top: 0;
  width: 2em !important;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}
.DayPicker-NavButton--prev {
  margin-right: 86% !important;
  outline: none;
  // background-image : url("../Icons/ArrowLeftIcon.svg") !important;
}
.DayPicker-NavButton--next {
  // background-image: url("../Icons/ArrowRightIcon.svg") !important;
  outline: none;
}
.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center !important;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
// .PhoneInputInput {
//   border: none !important;
// }
// .PhoneInputCountryIcon {
//   width: 40px;
//   height: 24px;
// }
// .PhoneInput:focus {
//   border: 1px solid #35a7ff !important;
//   padding: 0 15px;
//   border-radius: 4px;
//   height: 52px;
// }
// .PhoneInput {
//   border: 1px solid #e8e9f5;
//   padding: 0 15px;
//   border-radius: 4px;
//   height: 52px;
// }
// .PhoneInputCountrySelectArrow {
//   width: 10px !important;
//   height: 12px !important;
//   margin-left: 15px !important;
// }
// .PhoneInputCountry svg {
//   overflow: hidden;
//   vertical-align: middle;
//   position: relative;
//   top: -6px;
//   border-radius: 2px;
//   width: 30px;
//   height: 20px;
// }
/* Show the checkmark when checked */

.container12 input:checked ~ .checkmark:after {
  display: block;
}

.checkout-card {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-top: 7px solid #e8e9f5;
  // width: 282px;
  height: 250px;
  border-radius: 8px;
  padding: 24px;
}
.custom-drop-down .btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #35a7ff;
  box-sizing: border-box;
  border-radius: 4px;
  color: #010101;
}
.custom-drop-down .btn-success.focus,
.btn-success:focus {
  box-shadow: none !important;
  color: #010101;
}
.dropdown-toggle::after {
  display: none;
}
.custom-drop-down-date {
  height: 52px;
  width: 100%;
  background: #ffffff;
  color: #010101;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-drop-down-date .btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background: #ffffff;
  box-shadow: none;
  border: 1px solid #35a7ff;
  box-sizing: border-box;
  border-radius: 4px;
  color: #010101;
}
.custom-drop-down-date .btn-success.focus,
.btn-success:focus {
  box-shadow: none !important;
  color: #010101;
}
.custom-drop-down {
  height: 52px;
  width: 100%;
  background: #ffffff;
  color: #010101;

  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 4px;
}
.custom-drop-down:hover {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  color: #010101;
  box-sizing: border-box;
}
.custom-drop-down-date:hover {
  background: #ffffff;
  border: 1px solid #e8e9f5;
  color: #010101;
  box-sizing: border-box;
}
.dropdown-item {
  padding: 0.65rem 0.8rem !important;
}
.custom-dropdown-menu {
  position: absolute !important;
  width: 100%;
  text-align: right;
  background: #ffffff;
  padding: 0 !important;
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(15, 35, 41, 0.04);
  border-radius: 8px;
  left: auto;
  margin: 8px 0px;
  right: auto;
  bottom: auto;
  // transform: translate3d(0px, 54px, 0px);
}
.comming-soon {
  padding-top: 150px;
}
.comming-soon-social {
  padding-bottom: 150px;
}
.question-card {
  // height: 304px;
  background: rgba(232, 233, 245, 0.25);
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  margin-bottom: 12px;
  align-self: center;
  // margin: 0px 12px;
  padding: 24px;
}
.review-card {
  width: 384px;
  min-height: 254px;
  padding: 24px 32px;
  margin-right: 24px;
  background: #ffffff;
  // animation-name: fadeInLeft;
  //   animation-duration: 3s;
  // box-shadow: 0px 4px 12px rgba(15, 35, 41, 0.08);
  border: 1px solid #e8e9f5;
  border-radius: 8px;
}
.review-card-icon {
  width: 52px;
  height: 52px;
}
.review-container {
  position: relative;
  // top: 4400px;
  width: 100%;
  overflow: scroll;
  direction: rtl;
  // margin-left: -50vh;
}
.review-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: none;
}
.review-container::-webkit-scrollbar-track {
  box-shadow: none;
}
.review-container::-webkit-scrollbar {
  width: 0;
}
.faq-card {
  background-color: rgba(232, 233, 245, 0.25);
  padding: 116px 0 116px 0;
  margin-top: 50px;
}

.tag {
  width: 101.5px;
  height: 24.92px;
  position: absolute;
  top: 32px;
  left: 11px;
  background: #299cf7;
  transform: matrix(-0.71, 0.7, 0.71, 0.71, 0, 0);
}
.tag-pricing {
  width: 100.5px;
  height: 24.92px;
  position: absolute;
  top: 32px;
  left: 10px !important;
  background: #299cf7;
  transform: matrix(-0.71, 0.7, 0.71, 0.71, 0, 0);
}
.tag:after {
  top: 0px;
  content: "";
  position: absolute;
  bottom: 25px;
  right: 101px;
  border-top: 25px solid transparent;
  border-right: 25px solid #299cf7;
}

.tag:before {
  content: "";
  position: absolute;
  top: 0;
  right: -25px;
  border-top: 25px solid transparent;
  border-left: 25px solid #299cf7;
}

.placement-hero-img {
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
@media (max-width: 330px) {
  .complete-icon {
    height: 125px !important;
    margin-bottom: 24px;
  }

  .kid-compoment-image-3 {
    height: 100px !important;
  }
  .DayPicker-dashboard-wrap {
    width: 85% !important;
  }
}
@media (max-width: 1280px) and (max-height: 650px) {
  .banner-vedio-con {
    width: 400px !important;
  }
}
@media (max-width: 1280px) {
  .placement-button-container {
    width: 65% !important;
  }

  .complete-step-section {
    padding-right: 1.5vw !important;
  }
}

@media (max-width: 765px) {
  .video-width-faq {
    height: 350px !important;
    position: relative;
    top: 0px;
    left: 0;
  }
  .course-scrollable-img-section {
    height: 660px !important;
    padding-bottom: 80px;
    justify-content: flex-end;
  }
  .res-w-100 {
    width: 100% !important;
  }
  .placement-index-svg {
    width: 200px;
    height: 240px;
  }
  .res-padding-top-0 {
    padding-top: 0 !important;
  }
  .custom-container {
    width: 100% !important;
  }
  .res-section-center {
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
  .iframe-video-faq {
    top: auto !important;
  }
}
@media (max-width: 992px) {
  .placement-card-container {
    margin-top: -2rem;
  }
  .slick-list {
    width: 100%;
  }
  .placement-con {
    height: 100vh;
  }
  .placement-header {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .placement-hero-img svg {
    width: 100%;
    margin-top: -100px;
    transform: scale(1.1);
  }
  .navbar-light .navbar-toggler:focus {
    outline: none !important;
  }
  .teachers-subtitle {
    font-size: 14px;
  }
  .teacher-section-margin {
    margin-bottom: 0;
  }
  .list-center-ipad {
    display: flex;
    justify-content: center !important;
  }
  .teachers-title {
    font-size: 21px;
  }

  .scroll-section-title-margin {
    margin-bottom: -200px;
  }
  .course-blue-card-section {
    padding: 48px 0;
  }
  .react-multi-carousel-item {
    width: 260px !important;
  }
  .result-con-card-p {
    margin-right: -20px;
  }
  .carousel-con {
    top: 265px;
    padding-left: 15px;
  }
  .course-scrollable-text {
    font-size: 16px;
  }
  .course-card-subtitle {
    font-size: 16px;
  }
  .custom-course-border {
    width: 165px;
  }
  .course-page-banner-icon {
    padding: 40px 0;
    align-self: center;
  }
  .desktop-container {
    padding-right: 0;
    padding-left: 0;
    max-width: 100% !important;
  }
  .desktop-carousel-container {
    padding-right: 0;
    padding-left: 0;
    max-width: 100% !important;
  }
  .course-card-title-section {
    padding: 80px 0 48px 0;
  }
  .course-card-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .course-card-section-text {
    text-align: right;
  }
  .course-page-section {
    padding-top: 20px;
  }
  .course-whiteBtn {
    width: 100%;
    height: 100%;
  }
  .course-blue-section-text {
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 4px;
  }
  .result-con-card {
    padding: 24px;
    margin-top: 40px;
  }
  dl,
  ol,
  ul {
    padding: 0;
  }
  .course-blue-section {
    background-size: cover !important;
    height: 236px;
    padding: 40px;
    background: url("https://firebasestorage.googleapis.com/v0/b/digital-vim-270607.appspot.com/o/bg_mobile.png?alt=media&token=7c191e8a-1836-4310-b2f5-74117202da04")
      center center no-repeat !important;
    border-radius: 8px;
  }
  .course-card-title {
    font-size: 24px;
    line-height: 44px;
  }
  .course-step-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .react-multi-carousel-track {
    height: 500px;
    padding-left: 15px !important;
  }
  .certificate-img {
    width: 100%;
    height: 250px;
  }
  .course-border-con {
    justify-content: center;
  }
  .video-text-section {
    padding: 24px;
  }
  .course-scrollable-img-section {
    height: 560px;
    padding-bottom: 80px;
    justify-content: flex-end;
  }
  .level-text {
    font-size: 21px;
  }
  .carousel-button-group {
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
  }
  .course-page-center {
    align-self: center !important;
    justify-content: center !important;
  }
  .course-subscription-section {
    width: 100%;
  }
  .course-card-section-image {
    width: 100% !important;
    background-size: contain !important;
    margin: 55px 0;
  }
  .course-banner-text {
    font-size: 30px;
    text-align: center;
  }
  .course-banner-subtext {
    font-size: 16px;
    text-align: center;
  }
  .course-banner-subtext-bold {
    font-size: 16px;
    text-align: center;
  }
  .course-banner-blue-text {
    font-size: 30px;
    text-align: center;
  }
  .course-banner-con {
    position: relative;
    top: 0px;
    margin-top: 60px;
    flex-direction: column-reverse !important;
  }
  .course-banner-con-hide-laptop {
    display: block;
  }
  .placement-index-svg {
    width: 200px;
    height: 240px;
  }
  .text-align-end {
    justify-content: flex-end !important;
  }
  .complete-subs-subtitle {
    text-align: right !important;
  }
  .complete-subs-title {
    text-align: right !important;
  }
  .modal-footer {
    padding: 0;
  }
  .progress-bar-section {
    width: 160px;
  }
  .complete-modal .modal-dialog .modal-content {
    width: 100% !important;
  }
  .res-padding {
    margin: 80px 0 32px 0 !important;
  }
  .custom-box {
    padding: 26px;
    min-width: 100%;
  }
  .circle-box-text {
    font-size: 25px;
  }
  .steps-circles {
    width: 68px !important;
    height: 68px !important;
  }
  .res-step-border {
    top: 35px !important;
  }
  .child-card-btn {
    display: none;
  }
  .child-card-btn-mobile {
    display: block;
    width: 100%;
    height: 52px;
    background: #299cf7;
    border: 1px solid #299cf7;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .custom-modal .modal-dialog {
    min-width: 100%;
  }
  .res-test-font-size {
    font-size: 11px !important;
  }
  .complete-icon-container {
    margin-bottom: 40px;
  }
  .complete-icon-container p {
    bottom: -24px !important;
  }
  .complete-icon-container img {
    height: 140px;
    width: 100%;
  }
  .res-test-header-text {
    font-size: 12px !important;
  }
  .child-card-time {
    font-size: 14px;
  }
  .child-card-text {
    font-size: 14px;
    text-align: right;
  }
  .complete-card-with-icon {
    padding-bottom: 40px !important ;
  }
  .complete-custom-card {
    padding: 10px !important;
  }

  .complete-text-card {
    background: rgba(232, 233, 245, 0.25);
    padding: 28px;
  }

  .placement-timer-text {
    font-size: 12px !important;
  }
  .placement-index-title {
    font-family: Cairo;
    font-style: normal;
    font-weight: bold;
    font-size: 27px !important;
    text-align: center !important;
    color: #010101;
  }
  .placement-index-text {
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    text-align: center !important;
    color: #191c1f;
  }
  .res-sticky-btn {
    position: sticky;
    bottom: 0;
  }
  .scroll-section {
    width: 100% !important;
    height: 100px;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    direction: rtl;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 24px;
  }
  .scroll-section-kids {
    width: 100% !important;
    height: 100px;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    direction: rtl;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 15px;
  }
  .scroll-section::-webkit-scrollbar {
    display: none;
  }
  .scroll-section-kids::-webkit-scrollbar {
    display: none;
  }
  .complete-custom-card {
    flex-direction: column-reverse;
    align-items: center;
  }
  .complete-custom-card span {
    text-align: center !important;
  }
  .complete-step-section {
    min-width: 900px !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-right: 12px !important;
    margin-left: 22px !important;
    padding-left: 22px !important;
  }
  .complete-step-section-kids {
    min-width: 400px !important;
    margin: 0 !important;
  }
}

@media (max-width: 765px) {
  .complete-blue-image {
    height: 800px !important;
    width: 120%;
  }
  .hide-for-mobile-com {
    display: none;
  }
  .hide-for-laptop-com {
    display: block;
    margin-top: 20px;
    margin-bottom: 0px !important;
  }
  .complete-footer-con {
    flex-direction: column-reverse !important;
    height: 800px !important;
    width: auto !important;
    overflow: hidden;
  }
  .placement-note-text {
    width: 100%;
  }
  .kid-compoment-image-3 {
    height: 120px;
  }
}
@media (max-width: 650px) {
  .complete-blue-image {
    height: 700px !important;
  }
  .complete-footer-con {
    height: 700px !important;
  }
}
@media (max-width: 530px) {
  .complete-blue-image {
    height: 600px !important;
  }
  .hide-for-mobile-com {
    display: none;
  }
  .course-page-partner-icon {
    width: 100%;
  }
  .hide-for-laptop-com {
    display: block;
    margin-top: 20px;
  }
  .complete-footer-con {
    flex-direction: column-reverse !important;
    height: 600px !important;
    width: auto !important;
  }
  .placement-note-text {
    width: 100%;
  }
  .kid-compoment-image-3 {
    height: 120px;
  }
}
@media (max-width: 1033px) {
  .placement-button-container {
    width: 70% !important;
  }
}

@media (max-width: 992px) {
  .DayPicker-dashboard-wrap {
    width: 90.5%;
  }
  .placement-button-container {
    width: 80% !important;
    // display: flex !important;
    // flex-direction: row !important;
    .placement-button {
      flex: 1 !important;
      margin-left: 10px;
    }
  }
  .navbar-light .navbar-toggler:focus {
    outline: none !important;
  }
  .text-align-end {
    justify-content: flex-end !important;
  }
  .complete-subs-subtitle {
    text-align: right !important;
  }
  .complete-subs-title {
    text-align: right !important;
  }
  .modal-footer {
    padding: 0;
  }
  .progress-bar-section {
    width: 160px;
  }
  .complete-modal .modal-dialog .modal-content {
    width: 100% !important;
  }
  .res-padding {
    margin: 80px 0 32px 0 !important;
  }
  .custom-box {
    padding: 26px;
  }
  .circle-box-text {
    font-size: 25px;
  }
  .steps-circles {
    width: 68px !important;
    height: 68px !important;
  }
  .res-step-border {
    top: 35px !important;
  }
  .child-card-btn {
    display: none;
  }
  .child-card-btn-mobile {
    display: block;
    width: 100%;
    height: 52px;
    background: #299cf7;
    border: 1px solid #299cf7;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .custom-modal .modal-dialog {
    min-width: 100%;
  }
  .res-test-font-size {
    font-size: 11px !important;
  }
  .complete-icon-container {
    margin-bottom: 40px;
  }
  .complete-icon-container p {
    bottom: -24px !important;
  }
  .complete-icon-container img {
    height: 140px;
    width: 100%;
  }
  .res-test-header-text {
    font-size: 12px !important;
  }
  .child-card-time {
    font-size: 14px;
  }
  .child-card-text {
    font-size: 14px;
    text-align: right;
  }
  .complete-card-with-icon {
    padding-bottom: 40px !important ;
  }
  .complete-custom-card {
    padding: 10px !important;
  }

  .complete-text-card {
    background: rgba(232, 233, 245, 0.25);
    padding: 28px;
  }
  .res-w-100 {
    width: 100% !important;
  }

  .placement-timer-text {
    font-size: 12px !important;
  }
  .placement-index-title {
    font-family: Cairo;
    font-style: normal;
    font-weight: bold;
    font-size: 27px !important;
    text-align: right !important;
    color: #010101;
  }
  .placement-index-text {
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    text-align: right !important;
    color: #191c1f;
  }
  .res-sticky-btn {
    position: sticky;
    bottom: 0;
  }
  .scroll-section {
    width: 100% !important;
    height: 100px;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    direction: rtl;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 15px;
  }
  .scroll-section-kids {
    width: 100% !important;
    height: 100px;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    direction: rtl;
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 15px;
  }
  .scroll-section::-webkit-scrollbar {
    display: none;
  }
  .scroll-section-kids::-webkit-scrollbar {
    display: none;
  }
  .complete-custom-card {
    flex-direction: column-reverse;
    align-items: center;
  }
  .complete-custom-card span {
    text-align: center !important;
  }
  .complete-step-section-kids {
    min-width: 400px !important;
    margin: 0 !important;
  }

  .hide-for-laptop-complete {
    display: block !important;
    object-fit: contain;
  }
  .scroll-header {
    font-size: 21px !important;
  }
  .error404-text {
    font-family: Cairo;
    font-style: normal;
    font-weight: 600;
    font-size: 30px !important;
    text-align: center;
    color: #333333;
  }
  .laptop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .res-test-btn {
    min-width: 100% !important;
  }
  .test-subtext {
    font-size: 14px;
  }
  .test-text {
    font-size: 30px;
  }
  .complete-icon {
    height: 100%;
    margin-bottom: 24px;
    margin-top: 40px;
  }
  .complete-con {
    padding: 0 24px 0 24px;
    margin-top: 40px;
  }
  .complete-icon-inner-container {
    width: 100% !important;
  }
  .res-complete-icon-text {
    bottom: 4px !important;
    margin-bottom: 24px;
    font-size: 12px !important;
  }
  .complete-page-text {
    font-size: 21px;
    padding-top: 0;
  }
  .complete-page-bluetext {
    font-size: 14px;
    padding-top: 16px;
  }
  .complete-page-subtext {
    font-size: 14px;
    padding-top: 16px;
  }
  .failed-booking-img {
    width: 180px;
    height: 125.67px;
  }
  .res-svg {
    width: 100%;
    min-width: 330px;
  }
  .banner-center {
    height: 100%;
  }
  .faq-card-res-whatspptext {
    font-size: 14px !important;
  }

  .faq-card-res-text {
    font-size: 18px !important;
  }
  .faq-card-res-subtext {
    font-size: 14px !important;
  }
  .success-purchase-card {
    width: 100% !important;
  }
  .svg-box-content {
    height: 273px;
    width: 346px;
  }
  .svg-box-content-faq {
    height: 273px;
    width: 346px;
  }
  .padding-90 {
    padding-bottom: 90px !important;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .iframe-video-faq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-width-faq {
    width: 571px;
    height: 498px;
  }
  .video-width {
    width: 327px !important;
  }
  .iframe-video-faq .youtube-video {
    width: 302px !important;
    height: 231px;
    border-radius: 6px !important;
  }
  .iframe-video-faq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe-video {
    top: 0;
  }
  .about-card-padding-bottom {
    padding-bottom: 48px;
  }
  .success-prchase-card-padding {
    padding: 0 0 90px 0 !important;
  }
  .youtube-video {
    width: 302px !important;
    height: 230px;
    border-radius: 6px !important;
  }
  .about-baner-text {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }
  .about-baner-subtext {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .about-banner-container {
    height: 110vh;
  }
  .about-banner {
    height: 110vh;
  }
  .container {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;
  }
  .banner-cente {
    justify-content: center !important;
  }
  .live-con {
    margin-left: 0px !important;
  }
  .padding0 {
    padding: 0 15px !important;
  }
  .padding-0 {
    padding: 0 !important;
  }
  .col {
    display: block;
    padding-left: 0;
    float: left;
    // margin: 1% 0 1% 2%;
  }
  .res-center {
    justify-content: center !important;
  }
  .res-padding-col {
    padding: 0 15px !important;
  }
  .hide-for-laptop-relative {
    position: relative;
    display: block;
  }
  .res-sticky-bottom {
    background: #fff;
    padding: 24px 0;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: inset 0px 1px 0px #e8e9f5;
  }
  .modal-backdrop {
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
  }
  .res-middle-checkout-card {
    margin-top: -27px;
  }
  .card-with-icon-section {
    margin-top: 116px !important;
  }
  .section {
    top: 0;
  }
  .failed-booking-text {
    font-size: 26px;
    line-height: 32px;
  }
  .failed-booking-subtext {
    font-size: 14px;
    line-height: 24px;
  }
  .banner-vedio-holder {
    width: 100%;
  }
  .res-steps-width {
    width: 100% !important;
  }
  .booking-text {
    font-size: 26px;
  }
  .booking-text {
    font-size: 14px;
  }
  .booking-success-card {
    width: 100%;
  }
  .phone-dd .dropdown-menu {
    // min-width: 360px !important;
    min-width: 100% !important;
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: unset !important;
  }
  .hide-for-laptop {
    display: flex !important;
    justify-content: center !important;
    position: absolute;
    bottom: 100px;
  }
  .phone-input-fixed-label {
    top: 116px !important;
    left: 90px !important;
  }
  .navbar-toggler {
    padding: 0;
  }
  .hide-for-mobile {
    display: none !important;
  }
  .modal-content {
    width: 100% !important;
    height: 100% !important;
    display: grid;
  }
  .complete-modal-btn {
    width: 100% !important;
  }
  .checkout-card {
    width: 100%;
  }
  .DayPicker-NavButton--prev {
    // margin-right: 21em !important;
    // background-image: url("../Icons/ArrowLeftIcon.svg") !important;
  }
  .DayPicker-Caption {
    margin-left: -5px;
    margin-right: -5px;
  }
  .res-pricing-header {
    flex-direction: column-reverse !important;
    padding: 48px 0 40px 0 !important;
  }
  .res-pricing-header div {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .section {
    overflow-x: scroll;
    display: flex;
    direction: rtl;
    overflow-y: hidden;
    margin: 0 -24px;
    padding: 0 9px;
  }
  .faq-card-res-text {
    font-size: 18px !important;
  }
  .faq-card-res-subtext {
    font-size: 14px !important;
  }
  .success-purchase-card {
    width: 100% !important;
  }
  .svg-box-content {
    height: 273px;
    width: 346px;
  }
  .svg-box-content-faq {
    height: 273px;
    width: 346px;
  }
  .padding-90 {
    padding-bottom: 90px !important;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .iframe-video-faq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-width-faq {
    width: 571px;
    height: 498px;
  }
  .video-width {
    width: 327px !important;
  }
  .iframe-video-faq .youtube-video {
    width: 302px !important;
    height: 231px;
    border-radius: 6px !important;
  }
  .iframe-video-faq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe-video {
    top: 0;
  }
  .about-card-padding-bottom {
    padding-bottom: 48px;
  }
  .success-prchase-card-padding {
    padding: 0 0 90px 0 !important;
  }
  .youtube-video {
    width: 302px !important;
    height: 230px;
    border-radius: 6px !important;
  }
  .about-baner-text {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }
  .about-baner-subtext {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .about-banner-container {
    height: 110vh;
  }
  .about-banner {
    height: 110vh;
  }
  .container {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;
  }
  .banner-cente {
    justify-content: center !important;
  }
  .live-con {
    margin-left: 0px !important;
  }
  .padding0 {
    padding: 0 15px !important;
  }
  .col {
    display: block;
    padding-left: 0;
    float: left;
  }
  .res-center {
    justify-content: center !important;
  }
  .res-padding-col {
    padding: 0 15px !important;
  }
  .hide-for-laptop-relative {
    position: relative;
    display: block;
  }
  .res-sticky-bottom {
    background: #fff;
    padding: 24px 0;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: inset 0px 1px 0px #e8e9f5;
  }
  .modal-backdrop {
    width: 100%;
    height: 100%;
  }
  .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
  }
  .res-middle-checkout-card {
    margin-top: -27px;
  }
  .card-with-icon-section {
    margin-top: 116px !important;
  }
  .section {
    top: 0;
  }
  .failed-booking-text {
    font-size: 26px;
    line-height: 32px;
  }
  .failed-booking-subtext {
    font-size: 14px;
    line-height: 24px;
  }
  .banner-vedio-holder {
    width: 100%;
  }
  .res-steps-width {
    width: 100% !important;
  }
  .booking-text {
    font-size: 26px;
  }
  .booking-text {
    font-size: 14px;
  }
  .booking-success-card {
    width: 100%;
  }
  .phone-dd .dropdown-menu {
    min-width: 100% !important;
  }
  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: unset !important;
  }
  .hide-for-laptop {
    display: flex !important;
    justify-content: center !important;
    position: absolute;
    bottom: 100px;
  }
  .phone-input-fixed-label {
    top: 116px !important;
    left: 90px !important;
  }
  .navbar-toggler {
    padding: 0;
  }
  .hide-for-mobile {
    display: none !important;
  }
  .modal-content {
    width: 100% !important;
    height: 100% !important;
    display: grid;
  }
  .complete-modal-btn {
    width: 100% !important;
    margin-top: 20px;
  }
  .checkout-card {
    width: 100%;
  }

  .DayPicker-NavButton--prev {
    // background-image: url("../Icons/ArrowLeftIcon.svg") !important;
  }
  .DayPicker-Caption {
    margin-left: -5px;
    margin-right: -5px;
  }
  .res-pricing-header {
    flex-direction: column-reverse !important;
    padding: 48px 0 40px 0 !important;
  }
  .res-pricing-header div {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .section {
    overflow-x: scroll;
    display: flex;
    direction: rtl;
    overflow-y: hidden;
    margin: 0 -24px;
    padding: 0 9px;
  }

  .span_1_of_5 {
    width: 100%;
    position: relative;
    top: 36px;
  }
  .res-pricing-text {
    font-size: 30px !important;
  }
  .full-width-card {
    position: relative;
    left: 0;
  }
  .plan-section {
    margin-top: 50px;
  }
  .res-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  .restext-faq-center {
    text-align: center !important;
    padding-top: 48px !important;
    width: 100%;
  }
  .laptop-footer {
    display: none;
  }
  .mobile-footer {
    display: block;
  }
  .footer-column {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin-bottom: 24px;
  }
  .footer-column-links {
    margin-right: 0 !important;
    margin-bottom: 4px;
  }
  .faq-card {
    padding: 80px 0 80px 0;
  }
  .res-faq-img {
    height: 440px !important;
  }
  .res-faq-border {
    border-bottom: none !important;
  }
  .res-subscription-card-btn {
    width: 100% !important;
  }
  .res-desc-section-padding-bottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important ;
  }
  .res-complete-padding-bottom {
    margin-bottom: 80px;
    padding-bottom: 0 !important;
  }
  .res-desc-section-img-padding {
    margin: 48px 0;
  }
  .res-desc-section-subtext {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  .res-desc-section-desc {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
  }
  .res-desc-section-text {
    font-family: Cairo;
    right: 0 !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }
  .res-subscription-card-padding {
    padding: 24px !important;
  }
  #steps {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 21px !important;
  }
  .subscription-card-header {
    margin: 0 24px !important;
  }
  .res-subscription-card-padding-header {
    padding: 24px 0 !important;
  }
  .subscription-card-tab {
    width: -webkit-fill-available !important;
  }
  .navbar {
    padding: 14px 24px !important;
  }
  .navbar-light .navbar-brand img {
    width: 105px !important;
    height: 24px !important;
  }
  .navbar-light .navbar-brand .nav-logo-res {
    width: 105px !important;
    height: 24px !important;
    position: relative;
    right: 7px;
  }
  .res-banner-titletext {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 30px !important;
  }
  .res-banner-btn {
    width: 180px !important;
    height: 60px !important;
  }
  .subscription-section-header {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 21px !important;
  }
  .subscription-section-subheader {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  .res-banner-subtext {
    font-family: Cairo;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
  }
  .DayPicker {
    width: 100%;
  }
  .res-steps-width {
    width: 100%;
  }
  .review-container {
    position: relative;
    width: 100%;
    overflow: scroll;
    direction: rtl;
  }
  .subcription-section-laptop {
    display: none !important;
  }
  .subcription-section-mobile {
    display: flex !important;
    width: 100%;
  }
  .comming-soon {
    padding-top: 20px;
  }
  .coming-soon-flag-section {
    padding-bottom: 80px;
    justify-content: center !important;
    text-align: center !important;
    display: flex !important;
  }
  .coming-soon-mobile {
    display: block;
    width: 100%;
  }
  .coming-soon-mobile-subtext {
    display: block;
    text-align: center !important;
    font-size: 14px !important;
  }
  .coming-soon-mobile-text {
    display: block;
    text-align: center !important;
    width: 100%;
    font-size: 30px !important;
  }
  .coming-soon-laptop {
    display: none !important;
  }
  .comming-soon-logo {
    display: flex !important;
    justify-content: center !important;
  }
  .comming-soon-social {
    padding-bottom: 250px;
    justify-content: center;
    align-self: center;
    display: flex !important;
  }
  .comming-soon-icon {
    position: absolute !important;
    bottom: 0;
    left: 0;
  }
  .res-width {
    width: 90%;
  }
  .checkout-form-card {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .contact-container {
    max-width: 100%;
  }

  .custom-container {
    width: 590px;
  }
  .res-padding-right {
    padding: 0 15px;
  }
  .res-contact-icon {
    width: 100% !important;
    padding-top: 80px;
    height: 350px;
  }
  .column-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .res-input-100 {
    width: 100% !important;
  }
  .about-text-container {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .subcription-section-laptop {
    display: none;
  }
  .subcription-section-mobile {
    display: block;
  }

  .footer-container {
    background-color: #1b1b1b;
    min-height: 224px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .banner-padding-top {
    padding-top: 40px;
  }
  .res-flex-end {
    display: flex;
    justify-content: center;
  }
  .res-padding-bottom {
    padding-bottom: 50px;
  }
  .res-faq-social-padding {
    padding-bottom: 32px;
  }
  .restext-align-center {
    text-align: center !important;
    align-content: center !important;
    justify-content: center;
    width: 100%;
  }
  .res-section-center {
    display: flex;
    align-items: center;
    flex-direction: row-reverse !important;
  }
  .res-banner-reverse {
    display: flex;
    flex-direction: column-reverse;
    height: auto !important;
    align-items: center;
  }
  .faq-card {
    background-color: rgba(232, 233, 245, 0.25);
    margin-right: 0vh;
    margin-left: 0vh;
  }
  .navbar-light .res-phone-icon.navbar-brand img {
    width: 33px !important;
    height: 33px !important;
  }
  .res-phone-icon {
    display: block;
  }

  .scrollable-card-icon {
    width: 40px;
    height: 40px;
  }
  .review-card {
    width: 286px;
    padding: 24px;
    height: auto;
  }
  .review-card-icon {
    width: 32px;
    height: 32px;
  }
  .res-flex-start {
    display: flex;
    justify-content: center;
  }
  .image-holder {
    height: 154px;
    min-width: 154px;
  }

  .tag-pricing {
    width: 100.5px;
    height: 24.92px;
    position: absolute;
    top: 32px;
    left: 11px !important;
    background: #299cf7;
    transform: matrix(-0.71, 0.7, 0.71, 0.71, 0, 0);
  }

  .tag {
    width: 101.5px;
    height: 24.92px;
    position: absolute;
    top: 33px;
    background: #299cf7;
    left: -3px;
    transform: matrix(-0.71, 0.7, 0.71, 0.71, 0, 0);
  }
  .tag:after {
    top: 0px;
    content: "";
    position: absolute;
    bottom: 25px;
    right: 126px;
    border-top: 25px solid transparent;
    border-right: 25px solid #299cf7;
  }
  .navbar-nav {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .height-100vh {
    height: 96vh;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-style: normal;
    font-weight: 600;
    margin-left: 0;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e9f5;
    text-transform: capitalize;
    color: #333333;
    padding: 16px 0;
  }
}

@media (max-width: 765px) {
  .placement-hero-container {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .placement-hero-img svg {
    margin: 2rem 0;
    height: 32vh;
    transform: none;
  }
  .video-width-faq {
    height: 350px !important;
    position: relative;
    top: 0px;
    left: 0;
  }
  .placement-index-svg {
    width: 200px;
    height: 240px;
  }
  .res-padding-top-0 {
    padding-top: 0 !important;
  }
  .custom-container {
    width: 100% !important;
  }
  .res-section-center {
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
  .iframe-video-faq {
    top: auto !important;
  }
  .placement-button-container {
    width: 100% !important;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    .placement-button {
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  .placement-card-container {
    margin: 0 1vw;
    .placement-card {
      width: 100%;
      margin-bottom: 3rem;
      margin-top: -2rem;
    }
  }
  .placement-index-title {
    text-align: center !important;
  }
  .placement-index-text {
    text-align: center !important;
  }
}

@media (min-width: 1000px) {
  .review-container {
    // position: absolute;
    overflow: scroll;
    // margin-left: -5% !important;
    direction: rtl;
    // margin-left: -50vh;
  }
  .placement-hero-container {
    padding-right: 10px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
  .subcription-section-mobile {
    display: none !important;
  }
  .subcription-section-laptop {
    display: block;
  }
}

@media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1070px !important;
  }
  .desktop-container {
    max-width: 1070px !important;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
  .res-steps-width {
    width: 425px;
  }
  .full-width-card {
    left: 0;
  }
  .tag:after {
    top: 0px;
    content: "";
    position: absolute;
    bottom: 25px;
    right: 101px;
    border-top: 25px solid transparent;
    border-right: 25px solid #299cf7;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1070px !important;
  }

  .res-steps-width {
    width: 425px;
  }
  .full-width-card {
    left: 0;
  }
  .tag:after {
    top: 0px;
    content: "";
    position: absolute;
    bottom: 25px;
    right: 101px;
    border-top: 25px solid transparent;
    border-right: 25px solid #299cf7;
  }
}
.carousel-con {
  top: 164px;
  position: relative;
}

.logo-slick-slider .slick-slide {
  box-shadow: 0px 12px 36px rgba(15, 35, 41, 0.04);
  border-radius: 4px !important;
}

.logo-slick-slider .slick-slide div {
  margin-right: 24px !important;
}

.teachers-subtitle {
  font-size: 15px;
}
.carousel-movement-container {
  display: flex;
  justify-content: space-between;
  width: 430px;
  margin-bottom: -65px;
  margin-top: 60px;
  // position: relative
}
.carousel-slide-dot {
  border-radius: 60px;
  margin-top: 76px;
  width: 24px;
  height: 8px;
  background: white;
  margin-right: 20px !important;
  padding-left: 10px !important;
}

@media (max-width: 1000px) {
  .carousel-cont {
    margin-top: 40px;
    display: inline;
  }
  .logo-slick-slider .slick-slide div {
    margin-left: 1vw;
  }
  .course-card-title-section {
    padding-bottom: 70px !important;
  }
}
@media (max-width: 508px) {
  .logo-slick-slider .slick-slide div {
    margin-left: 1vw;
    margin-bottom: -14.5px;
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .DayPicker-dashboard-wrap {
    width: 89.8%;
  }
  .logo-slick-slider .slick-slide {
    div {
      width: 70vw;
      margin-left: 6.9vw;
      margin-bottom: 2px;
    }
  }
  .placement-button-container {
    width: 100% !important;
    margin-bottom: -10px !important;
    display: flex;
    flex-direction: column !important;
    .placement-button {
      margin: 0px;
      margin-bottom: 10px;
      padding: 10px;
    }
  }
  .placement-hero-img svg {
    height: 22vh;
    transform: none;
  }
}

@media (max-width: 400px) {
  .DayPicker-dashboard-wrap {
    width: 87.4vw;
  }
  .carousel-slide-dot {
    border-radius: 60px;
    margin-top: 77px;
    width: 15px;
    height: 8px;
    background: white;
  }

  .carousel-movement-container {
    width: 360px;
  }
  .dedicatedpage-blue-section {
    text-align: center;
    .course-whiteBtn {
      width: 80%;
    }
  }
}

.logo-slick-slider .slick-dots {
  li {
    cursor: default !important;
  }
}
@media (max-width: 1100px) and (min-width: 992px) {
  .dedicated-page-hero-text {
    padding-right: 3vw;
  }
  .res-con-width {
    width: 100% !important;
  }
}
